<script>
import is from "vue2-datepicker/locale/es/is";
import {bus} from "@/router/bus";

export default {
  name: "RateCardPathTable",
  
  props: {
    rate: { type: Object},
    schedule: { type: Array}
  },
  
  data: () => ({
    active: null,
    client_id: null,
    statisticData: {}
  }),
  
  computed: {
    is() {
      return is
    },
    
    isAuth() {
      return !!this.$_getsetting('is_auth');
    },
  },
  
  watch: {
    rate: {
      handler() {
        this.getRateStatistic()
      },
      deep: true
    },
  },
  
  mounted() {
    this.setClientId()
    this.getRateStatistic()
  },
  
  methods: {
    getRateStatistic() {
      this.$postapi(this.$address + this.$getters.getRateStatisticByKey.uri, {
        method: this.$getters.getRateStatisticByKey.name,
        key: this.rate.key_orig
      }).then(data => {
        if (data.error === 0) {
          this.statisticData = JSON.parse(data.message)
        }
      })
    },
    
    setClientId() {
      this.client_id = this.$_getsetting('client_id')
    },
    
    createchain(item, block, index){
      if (item.type_id == 259)
        return "";
      else {
        if (block == 0) {
          if (index == 1 && this.rate.detail[0].type_id == 215 && this.isAuth === false) {
            return "";
          } else {
            return ((item.ext_place_from.length > 0 && (item.type_id != 215 || this.isAuth === true))
              ? "(" + item.ext_place_from + ")" : "");
          }
        }
      }
    },
    
    getImage(service){
      switch(service.icon[0]) {
        case "1":
          return require("../../assets/img/icons/ship.svg")
        case "2":
          return require("../../assets/img/icons/train.svg")
        case "3":
          return require("../../assets/img/icons/container.svg")
        case "4":
          return require("../../assets/img/icons/car.svg")
      }
    },
    
    getNumber(value){
      return parseFloat(value).toLocaleString('ru-RU')
    },
    
    getDaysCount(service) {
      switch(Number(service.service_id)) {
        case 1:
            return this.getTzTime('term_sea')
        case 2:
          return this.getTzTime('tz_rw')
      }
    },
    
    getTzTime(key_name) {
      for (var i = 0; i < this.statisticData.data.length; i++) {
        if (this.statisticData.data[i].name === key_name) {
          return this.statisticData.data[i].value
        }
      }
    },
    
    getDaysColor(service) {
      switch(Number(service.service_id)) {
        case 1:
          return 'blue'
        case 2:
          return 'green'
        case 4:
          return 'grey'
      }
    },
    
    openLineInfo() {
      bus.$emit('showLineInfo')
    },
  }
}
</script>

<template>
  <div class="card-path" v-if="Object.keys(statisticData).length > 0">
    <table class="card-path-table" v-if="Object.keys(rate).length > 0">
      <thead>
        <tr class="card-path-table__tr-header">
          <td></td>
          <td>Этап</td>
          <td>Место отправки</td>
          <td v-if="isAuth">Подрядчик</td>
          <td>Фиксация ставки</td>
          <td class="hide-tablet">Курс</td>
          <td class="hide-tablet">Цена &#36;</td>
          <td>Цена &#8381;</td>
        </tr>
      </thead>
      <tr
        class="card-path-table__tr-body"
        v-for="(service, index) in rate.detail"
        :key="index"
      >
        <td class="card-path-table__tr-body__td-container">
          <img
            :alt="service.service"
            :title="service.service"
            class="rate-card-service-img"
            :src="getImage(service)"
          />
          <div
            class="card-path-table__tr-body-plate"
            :class="'card-path-table__tr-body-plate-' + getDaysColor(service)"
            v-if="index + 1 !== rate.detail.length && service.service_id < 3 && getDaysCount(service)"
          >
            {{getDaysCount(service)}} <span>&nbsp;дней</span>
          </div>
        </td>
        <td v-if="service.service === 'ЖД'"><p class="is_wagon">
          {{service.service}}
          <span v-if="rate.is_wagon">Повагонная отправка</span>
          <span v-else>Ускоренный контейнерный поезд</span>
        </p></td>
        <td v-else>{{service.service}}</td>
        <td class="card-path-table__tr-body-place">
          {{service.place_from}}
          <br>
          <span>{{createchain(service, 0, index)}}</span>
        </td>
        <td
          v-if="(Number(rate.is_hide_line) === 0 || getClientId === -1)"
          @click="openLineInfo">
          <span :class="Number(rate.has_line_info) === 1 ? 'card-path-table__tr-body-line-link' : 'card-path-table__tr-body-line'">
           {{service.transporter_name}}
          </span>
        </td>
        <td v-else-if="isAuth === true">Vedexx</td>
        <td class="card-path-table__fix"> {{service.condition_name}}</td>
        <td class="hide-tablet card-path-table__tr-body-price">{{service.cbr_rate}} </td>
        <td class="hide-tablet card-path-table__tr-body-price"> {{getNumber(service.summa)}}&nbsp;&nbsp;
          <span v-if="service.cur_code === 'USD'">&#36;</span>
          <span v-else>&#8381;</span>
        </td>
        <td class="card-path-table__tr-body-price-bold">{{getNumber(service.cost)}}&nbsp;&#8381;</td>
      </tr>
      
      <tr class="card-path-table__tr-total hide-tablet">
        <td :colspan="isAuth ? 7 : 6" class="td-right">Итого:</td>
        <td>{{getNumber(rate.total)}}&nbsp;&#8381;</td>
      </tr>
      
      <tr class="card-path-table__tr-total hide-desktop">
        <td colspan="5" class="td-right">Итого:</td>
        <td>{{getNumber(rate.total)}}&nbsp;&#8381;</td>
      </tr>
    </table>
  </div>
</template>

<style scoped lang="scss">
table {
  width: 100%;
  border-collapse: collapse;
}

.card-path {
  display: flex;
  justify-content: center;
}

.card-path-table {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  
  &__tr-header {
    color: var(--grey-70);
    letter-spacing: 0.12px;

    @include font-description-1;
    
    > td {
      padding: 4px 0 16px;
      
      &:not(:first-child) {
        border-bottom: 1px solid var(--grey-20);
      }
    }
  }
  
  &__tr-body {
    @include font-subtitle-regular;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--grey-70);
    position: relative;
    
    @media (max-width: $mobile-width) {
      font-size: 12px;
    }
    
    > td {
      padding: 30px 0;
      //position: relative;
      border-bottom: 1px solid var(--grey-20);

      @media print {
        padding: 8px 0;
        font-size: 12px;
      }
    }
    
    &__td-container {
      padding-right: 20px !important;
      border-bottom: none !important;
      padding-left: 10px !important;

      @media (max-width: $tablet-width){
        padding-left: 0 !important;
      }
    }
    
    &-plate {
      border-radius: 4px;
      margin-left: -20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 16px;
      margin-top: 18px;
      position: absolute;
      color: var(--grey-70);
      text-align: center;
      @include font-description-1;

      @media print {
        display: none;
      }

      @media (max-width: $tablet-width){
        padding: 4px 10px;
      }

      &-blue {
        background: var(--blue-10);
      }
      
      &-green {
        background: var(--green-10);
      }
      
      &-grey {
        background: var(--grey-10);
      }
    }
    
    &-place {
      width: 15%;
      color: var(--grey-100);
      letter-spacing: 0.18px;

      @include font-body-2;

      @media (max-width: $tablet-width){
        font-size: 14px;  
      }

      @media print {
        font-size: 12px;
      }

      > span {
        color: var(--grey-70);
        margin-top: 4px;
        display: block;
        font-weight: 400;
      }
    }
    
    &-line {
      width: 10%;
      letter-spacing: 0.14px;

      @include font-body-2;

      @media print {
        font-size: 12px;
      }

      &-link {
        color: var(--blue-50);
        text-decoration-line: underline;
        cursor: pointer;
        
        &:active {
          text-decoration: none;
        }
      }
    }

    &-price {
      font-size: 16px;
      width: 10%;

      @media print {
        font-size: 12px;
      }

      &-bold {
        width: 10%;
        font-size: 16px;
        font-weight: 500;

        @media print {
          font-size: 12px;
        }
      }
    }
  }
  
  &__fix {
    max-width: 10%;
  }
  
  &__tr-total {
    border: none;

    > td {
      padding-top: 16px;

      @media print {
        padding-top: 4px;
        font-size: 12px;
      }

      &:first-child {
        color: var(--grey-70);
        letter-spacing: 0.14px;
        padding-right: 6px;
        padding-top: 20px;

        @include font-body-3;

        @media print {
          padding-top: 4px;
          font-size: 12px;
        }
      }
      
      &:last-child {
        @include font-headline-2;

        color: var(--blue-70);
        font-size: 24px;
        letter-spacing: 0.24px;

        @media print {
          padding-top: 4px;
          font-size: 14px;
        }
      }
    }
  }
}

.td-right {
  text-align: right;
}

.rate-card-service-img{
  width: 28px;
  height: 28px;
}

.hide-tablet {
  
  @media (max-width: $tablet-width) {
    display: none;
  }
}

.hide-desktop {
  display: none;
  
  @media (max-width: $tablet-width) {
    display: table-footer-group;
  }
}

@media print{
  .hide-tablet{
    display: table-cell;
  }

  .card-path-table__tr-total.hide-tablet{
    display: table-footer-group;
  }

  @media (max-width: 1200px){
    .card-path-table__tr-total.hide-tablet{
    display: none;
    }

    .card-path-table__tr-total.hide-desktop{
      display: table-footer-group;
    }

    td.card-path-table__tr-body__td-container{
      padding: 5px 10px 5px 0;
      padding-left: 0 !important;
    }

    .section__body{
      padding: 16px;
    }
  }
}
.is_wagon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px 0;
  max-width: 123px;
  margin-top: 20px;

  @media (max-width: $tablet-width){
    max-width: 90px;
  }

  >span{
    color: var(--green-100);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
  }
}

</style>
