<script>
import {bus} from "../../router/bus";

export default {
  name: "RateCardPathTableMobile",
  
  props: {
    rate: { type: Object, },
    schedule: { type: Array}
  },
  
  data: () => ({
    active: null,
    client_id: null,
    statisticData: {},
  }),
  
  computed: {
    isAuth() {
      return !!this.$_getsetting('is_auth');
    },

    isCountSeaDates() {
      if (Number(this.rate.is_unverified) === 0 && this.rate.all_time < 9999 && this.rate.tranzit_time < 9999) {
        return true;
      }
      return false;
    }
  },
  
  mounted() {
    this.setClientId()
    this.getRateStatistic()
  },
  
  watch: {
    rate: {
      handler(newValue) {
        this.rate = newValue
        this.getRateStatistic()
      },
      deep: true
    },
  },
  
  methods: {
    setClientId() {
      this.client_id = this.$_getsetting('client_id')
    },
    
    getRateStatistic() {
      this.$postapi(this.$address + this.$getters.getRateStatisticByKey.uri, {
        method: this.$getters.getRateStatisticByKey.name,
        key: this.rate.key_orig
      }).then(data => {
        if (data.error == 0) {
          this.statisticData = JSON.parse(data.message)
        }
      })
    },
    
    createchain(item, block, index){
      if (item.type_id == 259)
        return item.place_from
      else {
        if (block == 0) {
          if (index == 1 && this.rate.detail[0].type_id == 215 && this.isAuth === false) {
            return item.place_from
          } else {
            return item.place_from + ((item.ext_place_from.length > 0 && (item.type_id != 215 || this.isAuth === true))
              ? " (" + item.ext_place_from + ")" : "")
          }
        } else {
          return item.place_to + ((item.ext_place_to.length > 0 && (item.type_id != 215 || this.isAuth === true))
            ? " (" + item.ext_place_to + ")" : "")
        }
      }
    },
    
    getImage(service){
      switch(service.icon[0]) {
        case "1":
          return require("../../assets/img/icons/ship.svg")
        case "2":
          return require("../../assets/img/icons/train.svg")
        case "3":
          return require("../../assets/img/icons/container.svg")
        case "4":
          return require("../../assets/img/icons/car.svg")
      }
    },
    
    getNumber(value){
      return parseFloat(value).toLocaleString('ru-RU')
    },

    getSeaAverageCount() {
      if (this.schedule.length === 0) return 0;

      let totalDays = 0;
      let scheduleValidDays = 0
      this.schedule.forEach(item => {
        if (item.sea_end_date && item.sea_start_date) {
          const startDate = new Date(item.sea_start_date);
          const endDate = new Date(item.sea_end_date);

          const differenceInTime = endDate - startDate;
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);

          totalDays += differenceInDays;
          scheduleValidDays ++
        }
      });
      const averageDays = totalDays / scheduleValidDays
      return Math.ceil(averageDays);
    },

    getDaysCount(service) {
      switch(Number(service.service_id)) {
        case 1:
          if (this.isCountSeaDates) {
            return this.getSeaAverageCount() !== 0 ? this.getSeaAverageCount() : this.getTzTime('term_sea');
          } else {
            return this.getTzTime('term_sea')
          }
        case 2:
          return this.getTzTime('tz_rw')
      }
    },

    getTzTime(key_name) {
      for (var i = 0; i < this.statisticData.data.length; i++) {
        if (this.statisticData.data[i].name === key_name) {
          return this.statisticData.data[i].value
        }
      }
    },
    
    getDaysColor(service) {
      switch(Number(service.service_id)) {
        case 1:
          return 'blue'
        case 2:
          return 'green'
        case 4:
          return 'grey'
      }
    },
    
    openLineInfo() {
      bus.$emit('showLineInfo')
    },
  }
}
</script>

<template>
  <div v-if="Object.keys(rate).length > 0">
    <div v-for="(service, index) in rate.detail" :key="index">
      <table class="card-path-table">
        <tr>
          <td class="card-path-table__img">
            <img :alt="service.service" :title="service.service" :src="getImage(service)"/>
          </td>
          
          <td>
            <table>
              <tr class="card-path-table__tr-body">
                <td>Этап:</td>
                <td class="card-path-table__tr-body" v-if="service.service === 'ЖД'"><p class="is_wagon">
                  {{service.service}}
                  <span v-if="rate.is_wagon">Повагонная отправка</span>
                  <span v-else>Ускоренный контейнерный поезд</span>
                </p></td>
                <td class="card-path-table__tr-body" v-else>{{service.service}}</td>
              </tr>
              
              <tr class="card-path-table__tr-body">
                <td>Отправление:</td>
                <td class="card-path-table__tr-body__place">{{createchain(service, 0, index)}}</td>
              </tr>
              
              <tr class="card-path-table__tr-body">
                <td v-if="isAuth">Подрядчик:</td>
                <td
                  v-if="(Number(rate.is_hide_line) === 0 || client_id === -1)"
                  @click="openLineInfo">
                <span :class="Number(rate.has_line_info) === 1 ? 'card-path-table__tr-body-line-link' : 'card-path-table__tr-body-line'">
                  {{service.transporter_name}}
                </span>
                </td>
                <td class="card-path-table__tr-body__line" v-else-if="isAuth === true">Vedexx</td>
              </tr>
              
              <tr class="card-path-table__tr-body">
                <td>Фиксация ставки:</td>
                <td class="card-path-table__tr-body__fix"> {{service.condition_name}}</td>
              </tr>
              
              <tr class="card-path-table__tr-body">
                <td>Цена</td>
                <td class="card-path-table__tr-body__price">
                  <div>{{getNumber(service.summa)}}&nbsp;&nbsp;
                    <span v-if="service.cur_code == 'USD'">&#36;</span>
                    <span v-else>&#8381;</span>
                  </div>
                  <div> {{getNumber(service.cost)}}&nbsp;&#8381;</div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      
      <div class="card-path-table-days">
        <div
          class="card-path-table__plate"
          :class="'card-path-table__plate-' + getDaysColor(service)"
          v-if="index + 1 !== rate.detail.length"
        >
          {{getDaysCount(service)}} <span>&nbsp;дней</span>
        </div>
        <div class="card-path-table__hr"></div>
      </div>
    </div>
    
    <table class="card-path-table__total">
      <td class="td-right"></td>
      <td>Итого: <span>{{getNumber(rate.total)}}&nbsp;&#8381;</span></td>
    </table>
  </div>
</template>

<style scoped lang="scss">
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
}

.card-path-table {
  width: 100%;
  margin: auto;
  
  &-days {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0 0 16px;
  }
  
  &__hr {
    height: 1px;
    width: calc(100% - 80px);
    background: var(--grey-20);
    align-items: flex-end;
    margin-left: auto;
  }
  
  &__plate {
    border-radius: 4px;
    align-items: center;
    display: flex;
    padding: 4px 8px;
    margin-left: 5px;
    color: var(--grey-70);
    text-align: center;
    max-width: 62px;

    @include font-description-1;
    
    &-blue {
      background: var(--blue-10);
    }
    
    &-green {
      background: var(--green-10);
    }
    
    &-grey {
      background: var(--grey-10);
    }
  }
  
  &__img {
    position: relative;
    border-top: none !important;
    padding-right: 15px;
    
    > img {
      position: absolute;
      top: 0;
      right: 10px;
      height: 21px;
      width: 21px;
    }
  }
  
  &__tr-body {
    @include font-description-1;

    align-content: flex-start;
    line-height: 17px;
    text-align: left;
    margin: 0 0 $size-8;
    color: var(--grey-70);
    letter-spacing: 0.12px;
    
    > td {
      padding-bottom: $size-12;
      width: calc(100% / 2);
      
      &:first-child {
        align-content: flex-start;
        font-weight: 500;
        text-align: left;
        padding-right: $size-12;
      }
    }
    
    &__place {
      @include font-description-semibold-0;

      text-align: left;
      color: var(--grey-100);
      font-size: 16px;
      letter-spacing: 0.16px;
    }
    
    &__fix {
      max-width: 120px;
    }
    
    &-line {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.14px;

      @include font-description-0;
      
      &-link {
        color: var(--blue-50);
        text-decoration-line: underline;
        cursor: pointer;
        
        &:active {
          text-decoration: none;
        }
      }
    }
    
    &__price {
      color: var(--grey-100);
      font-size: 14px;
      letter-spacing: 0.14px;
      
      > div {
        &:last-child {
          font-weight: 700;
          margin-top: 4px;
        }
      }
    }
  }
  
  &__total {
    padding-top: 10px;
    
    > td {
      width: calc(100% / 2);
      color: var(--grey-70);
      letter-spacing: 0.14px;

      @include font-body-3;
      
      > span {
        color: var(--blue-70);
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.2px;
      }
    }
  }
  
  
  &__tr-total {
    @include font-body-bold-2;

    background-color: #fff;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #626262;
  }
}

.td-right {
  text-align: right;
}

.td-center{
  text-align: center;
}

.hide-mobile {
  
  @media (max-width: $mobile-width) {
    display: none;
  }
}

.hide-desktop {
  
  @media (min-width: $mobile-width) {
    display: none;
  }
}

.is_wagon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px 0;
  max-width: 123px;
  margin-top: 10px;

  >span{
    color: var(--green-100);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
  }
}
</style>
