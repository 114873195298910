<script>
export default {
  name: 'RatePageAdditional',
  
  props: {
    additional: {
      type: Array,
    },
  },
  
  methods: {
    getNumber(value) {
      return parseFloat(value).toLocaleString("ru-RU");
    }
  }
}
</script>

<template>
  <div class="additional" v-if="additional.length > 0">
    <div class="additional__container">
      <div class="additional__container-plate" v-for="item in additional" :key="item.id">
        <div class="additional__container-plate-title">{{ item.obj_name }}</div>
        <div class="additional__container-plate-price">
          {{ getNumber(item.price)}}&nbsp;
          <span v-if="item.currency_id == '840'">&#36;</span>
          <span v-else>&#8381;</span>
        </div>  
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.additional {
  padding-bottom: 16px;
  
  &__container {
    @include font-body-2;
    
    padding-top: 15px;
    font-size: 24px;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 16px;
    
    @media (max-width: $tablet-width) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media print {
      grid-column-gap: 32px;
      grid-row-gap: 4px;
      padding-top: 8px;
      grid-template-columns: repeat(1, 1fr);
    }
    
    &-plate {
      border-radius: 8px;
      background: var(--grey-5);
      padding: 20px 16px;
      display: flex;
      justify-content: space-between;

      @media print {
        padding: 6px 8px;
        background: none;
        border: 1px solid var(--grey-20);
      }

      &-title {
      color: var(--grey-100);
      letter-spacing: 0.14px;
      margin-right: 16px;

      @include font-description-0;

        @media print {
          font-size: 12px;
        }
      }
      
      &-price {
        @include font-description-semibold-0;

        color: var(--grey-70);
        font-size: 16px;
        letter-spacing: 0.16px;
        display: flex;
        white-space: nowrap;

        @media print {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
